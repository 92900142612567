import { useAtom } from "jotai/react";
import invariant from "tiny-invariant";
import { WSimpleSelect, toSimpleItem } from "../../../components/dev/WSimpleSelect.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { updateConfigOverrideAtom } from "../../../config.override.ts";
import { allThemes } from "../../theme/allThemes.ts";

const favIcons = import.meta.glob("/src/styles/*/favicon.svg", { eager: true, query: "?url", import: "default" });

function getFavIconUrl(theme: string) {
    return favIcons[`/src/styles/${theme}/favicon.svg`] as string | undefined;
}

export function SiteThemeSelect() {
    invariant(Object.entries(favIcons).length, "favicons not found");

    const [config, updateConfig] = useAtom(updateConfigOverrideAtom);

    const themes = [undefined, ...Object.keys(allThemes)].map(toSimpleItem);

    return (
        <WSimpleSelect
            placeholder="Site theme"
            value={config.siteTheme}
            items={themes}
            onChange={(value) => {
                updateConfig((config) => {
                    config.siteTheme = value;
                });
            }}
        >
            {(item) => (
                <span className="flex gap-0.5em">
                    {item.value ? (
                        <>
                            <img className="size-1rem block" src={getFavIconUrl(item.value)} alt={item.value} />
                            <span>{item.value}</span>
                        </>
                    ) : (
                        <>
                            <MaskIcon className="size-1rem jp-icon-checkmark color-muted" />
                            <span className="color-muted">Default</span>
                        </>
                    )}
                </span>
            )}
        </WSimpleSelect>
    );
}

export default SiteThemeSelect;
